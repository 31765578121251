import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../../LuminyLogo.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gradient-to-r from-galacticPalePink to-galacticPink p-4 shadow-lg">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="text-2xl font-bold text-galacticBlue">
          <img src={logo} alt="Luminy Logo" className="h-8 inline mr-2" />
        </div>
        <div className="hidden md:flex space-x-4 text-galacticBlue">
          <Link to="/" className="hover:text-hoverLight transition duration-300">Home</Link>
          <Link to="/about" className="hover:text-hoverLight transition duration-300">About</Link>
          <Link to="/services" className="hover:text-hoverLight transition duration-300">Services</Link>
          <Link to="/contact" className="hover:text-hoverLight transition duration-300">Contact</Link>
          <Link to="/generator" className="hover:text-hoverLight transition duration-300">Generator</Link>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            {isOpen ? <XMarkIcon className="h-6 w-6 text-galacticBlue" /> : <Bars3Icon className="h-6 w-6 text-galacticBlue" />}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden mt-2 text-galacticBlue">
          <Link to="/" className="block py-2 hover:text-hoverLight transition duration-300" onClick={toggleMenu}>Home</Link>
          <Link to="/about" className="block py-2 hover:text-hoverLight transition duration-300" onClick={toggleMenu}>About</Link>
          <Link to="/services" className="block py-2 hover:text-hoverLight transition duration-300" onClick={toggleMenu}>Services</Link>
          <Link to="/contact" className="block py-2 hover:text-hoverLight transition duration-300" onClick={toggleMenu}>Contact</Link>
          <Link to="/generator" className="block py-2 hover:text-hoverLight transition duration-300" onClick={toggleMenu}>Generator</Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
