import React from 'react';
import { Link } from 'react-router-dom';
import background from '../../background.jpg';

const Home = () => {
  return (
    <div className="bg-galacticBlue-100">
      <main>
        <section className="bg-cover bg-center h-screen" style={{ backgroundImage: `url(${background})` }}>
          <div className="container mx-auto px-4 h-full flex flex-col justify-center items-center text-center">
            <h1 className="text-4xl md:text-6xl text-white font-bold mb-4">Bienvenidos a Luminy Studio</h1>
            <p className="text-xl text-white mb-8">Especialistas en grabado láser, imprenta y diseño gráfico</p>
            <Link to="/contact" className="bg-galacticPink text-white py-3 px-6 rounded-full text-lg">Contáctanos</Link>
          </div>
        </section>
        <section className="py-12">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-8">Nuestros Servicios</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Grabado Láser</h3>
                <p className="text-gray-700">Ofrecemos grabado láser de alta precisión para personalizar una amplia variedad de materiales.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Imprenta</h3>
                <p className="text-gray-700">Soluciones de impresión de alta calidad para todas tus necesidades, desde tarjetas de presentación hasta carteles.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Diseño Gráfico</h3>
                <p className="text-gray-700">Nuestros diseñadores gráficos crearán diseños únicos y atractivos para tu marca.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-12 bg-gray-200">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-8">Portafolio</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <img src="/path-to-project1.jpg" alt="Project 1" className="mb-4 rounded-lg" />
                <h3 className="text-xl font-semibold mb-2">Proyecto 1</h3>
                <p className="text-gray-700">Descripción breve del proyecto.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <img src="/path-to-project2.jpg" alt="Project 2" className="mb-4 rounded-lg" />
                <h3 className="text-xl font-semibold mb-2">Proyecto 2</h3>
                <p className="text-gray-700">Descripción breve del proyecto.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <img src="/path-to-project3.jpg" alt="Project 3" className="mb-4 rounded-lg" />
                <h3 className="text-xl font-semibold mb-2">Proyecto 3</h3>
                <p className="text-gray-700">Descripción breve del proyecto.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-12">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-8">Testimonios</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-700 mb-4">"Excelente servicio y calidad en el trabajo. Muy recomendados!"</p>
                <h3 className="text-xl font-semibold">Cliente 1</h3>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-700 mb-4">"La atención al detalle y la profesionalidad son inigualables."</p>
                <h3 className="text-xl font-semibold">Cliente 2</h3>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-700 mb-4">"Muy contento con los resultados. Definitivamente volveré."</p>
                <h3 className="text-xl font-semibold">Cliente 3</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="py-12 bg-blue-500 text-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-4">¿Listo para empezar tu proyecto?</h2>
            <p className="text-xl mb-8">Ponte en contacto con nosotros y hagamos realidad tu visión.</p>
            <Link to="/contact" className="bg-white text-blue-500 py-3 px-6 rounded-full text-lg">Contáctanos</Link>
          </div>
        </section>
      </main>
      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto px-4 text-center">
          <div className="flex justify-center space-x-4 mb-4">
            <Link to="/" className="hover:text-gray-400">Home</Link>
            <Link to="/about" className="hover:text-gray-400">About</Link>
            <Link to="/services" className="hover:text-gray-400">Services</Link>
            <Link to="/contact" className="hover:text-gray-400">Contact</Link>
          </div>
          <p>&copy; 2024 Luminy Studio. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
