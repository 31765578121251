import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FrontView from '../FrontView';
import BackView from '../BackView';

const TumblerTable = ({ index, color, handleColorChange, handleRemove }) => {
  const [frontTopImage, setFrontTopImage] = useState(null);
  const [frontBottomImage, setFrontBottomImage] = useState(null);
  const [backTopImage, setBackTopImage] = useState(null);
  const [backBottomImage, setBackBottomImage] = useState(null);

  const handleColorInputChange = (event) => {
    handleColorChange(index, event.target.value);
  };

  const handleImageChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="relative p-4 border rounded-lg bg-gradient-to-r from-gray-700 to-gray-500 text-pastelPink mb-4 shadow-lg">
      <button
        onClick={() => handleRemove(index)}
        className="absolute top-0 right-0 mt-2 mr-2 text-red-500 hover:text-red-700"
      >
        &times;
      </button>
      <h3 className="text-xl font-bold mb-2">Termo {index + 1}</h3>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <h4 className="text-lg font-semibold mb-2 text-center">Vista Frontal</h4>
          <div className="flex justify-center items-center">
            <FrontView color={color} topImage={frontTopImage} bottomImage={frontBottomImage} />
          </div>
          <div className="mt-4">
            <label className="block text-center mb-1">Imagen Superior Frontal</label>
            <input type="file" onChange={(event) => handleImageChange(event, setFrontTopImage)} className="mt-1 p-2 block w-full rounded-md bg-gray-700 border-gray-600" />
            <label className="block text-center mb-1">Imagen Inferior Frontal</label>
            <input type="file" onChange={(event) => handleImageChange(event, setFrontBottomImage)} className="mt-1 p-2 block w-full rounded-md bg-gray-700 border-gray-600" />
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <h4 className="text-lg font-semibold mb-2 text-center">Vista Trasera</h4>
          <div className="flex justify-center items-center">
            <BackView color={color} topImage={backTopImage} bottomImage={backBottomImage} />
          </div>
          <div className="mt-4">
            <label className="block text-center mb-1">Imagen Superior Trasera</label>
            <input type="file" onChange={(event) => handleImageChange(event, setBackTopImage)} className="mt-1 p-2 block w-full rounded-md bg-gray-700 border-gray-600" />
            <label className="block text-center mb-1">Imagen Inferior Trasera</label>
            <input type="file" onChange={(event) => handleImageChange(event, setBackBottomImage)} className="mt-1 p-2 block w-full rounded-md bg-gray-700 border-gray-600" />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h4 className="text-lg font-semibold mb-2">Elige un color para el Termo {color}</h4>
        <input
          type="color"
          value={color}
          onChange={handleColorInputChange}
          className="mt-1 p-2 block w-12 h-12 rounded-md bg-gray-700 border-gray-600"
        />
      </div>
    </div>
  );
};

TumblerTable.propTypes = {
  index: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  handleColorChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default TumblerTable;
