import React, { useState } from "react";
import "./App.css";
import AppUI from "./AppUI";

const App = () => {
  const [tumblers, setTumblers] = useState([]);

  const handleGenerate = () => {
    setTumblers((prevTumblers) => [
      ...prevTumblers,
      { id: prevTumblers.length + 1, color: "#ffffff" },
    ]);
  };

  const handleColorChange = (index, color) => {
    setTumblers((prevTumblers) =>
      prevTumblers.map((tumbler, idx) =>
        idx === index ? { ...tumbler, color: color } : tumbler
      )
    );
  };

  return (
    <AppUI
      tumblers={tumblers}
      handleGenerate={handleGenerate}
      handleColorChange={handleColorChange}
    />
  );
};

export default App;
