import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import TumblerGenerator from './pages/TumblerGenerator';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

const Root = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="contact" element={<Contact />} />
        <Route path="generator" element={<TumblerGenerator />} />
      </Route>
    </Routes>
  </Router>
);

root.render(<Root />);
