import React, { useState } from 'react';
import TumblerTable from '../../components/TumblerTable';

const TumblerGenerator = () => {
  const [tumblers, setTumblers] = useState([]);
  const [generateCount, setGenerateCount] = useState(1);

  const handleGenerate = () => {
    const newTumblers = Array.from({ length: generateCount }, (_, index) => ({
      id: index + 1,
      color: '#ffffff',
    }));
    setTumblers(newTumblers);
  };

  const handleColorChange = (index, color) => {
    setTumblers((prevTumblers) =>
      prevTumblers.map((tumbler, idx) =>
        idx === index ? { ...tumbler, color: color } : tumbler
      )
    );
  };

  const handleRemove = (index) => {
    setTumblers((prevTumblers) => {
      const updatedTumblers = prevTumblers.filter((_, idx) => idx !== index);
      setGenerateCount(updatedTumblers.length);
      return updatedTumblers;
    });
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex items-center mb-4">
        <button onClick={handleGenerate} className="bg-blue-500 text-white p-2 rounded mr-4">
          Generate Tumbler
        </button>
        <input
          type="number"
          value={generateCount}
          onChange={(e) => setGenerateCount(Number(e.target.value))}
          min="1"
          className="p-2 rounded-md bg-gray-700 border-gray-600 text-white"
        />
      </div>
      {tumblers.map((tumbler, index) => (
        <TumblerTable
          key={tumbler.id}
          index={index}
          color={tumbler.color}
          handleColorChange={handleColorChange}
          handleRemove={handleRemove}
        />
      ))}
    </div>
  );
};

export default TumblerGenerator;
