import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

const BackView = ({ color, topImage, bottomImage }) => {
  const topNodeRef = useRef(null);
  const bottomNodeRef = useRef(null);

  useEffect(() => {
    // Lógica de efecto aquí si se necesita en el futuro
  }, [color]);

  return (
    <div style={{ position: 'relative', width: '200px', height: '400px' }}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 387.67 763.14"
        width="200"
        height="400"
      >
        <defs>
          <style>{`.cls-1{fill:#6b6b6b;}`}</style>
        </defs>
        <path
          id="base"
          data-name="base"
          className="cls-1"
          d="M3.56,44.83a31.65,31.65,0,0,0,7.35,3.93,1414.54,1414.54,0,0,1,376.76,1.39V48.63c0-7.86-.12-15.72,0-23.59a8,8,0,0,0-2.79-6.21,19.54,19.54,0,0,0-7.23-4.18A87.08,87.08,0,0,0,365,11.48a374.84,374.84,0,0,0-49.2-6.35c-14.08-.76-28.28-2.91-42.35-3.42C253,1.08,232.71,1.2,212.29.19c-9.89-.51-19.78.13-29.8.51L127.84,2.6c-16.36.51-32.72,1.27-48.95,2.91C61.51,7.16,44.14,9.57,27.15,14.14c-2.92.76-6,1.9-7.74,4.82-.76,1.39-2.15,1.77-3.68,1.77-3.93,0-7.86.89-11.66,1.4C2.16,22.38.64,23,.64,25.42.52,29.61.26,33.92,0,38.11A7.45,7.45,0,0,0,3.56,44.83Z"
        />
        <path
          id="base-bottom"
          data-name="base-bottom"
          className="cls-1"
          d="M67.73,689.79c.25,6.72.5,13.32.88,20,.26,3.55,1,6.85,3.17,9.51a88.57,88.57,0,0,0,20.8,19.91c14.2,9.76,30.31,14.71,46.92,18.13a286.68,286.68,0,0,0,33.23,4.7c19.53,1.64,39.05,1.52,58.2-1.4,19.41-2.92,38.3-8.12,55.67-17.75,8.88-4.95,16.36-11.54,21-20.8,3.68-7.23,3.43-15.09,4.44-22.7.13-.63.13-1.27.26-1.9A309.63,309.63,0,0,1,67.73,689.79Z"
        />
        <path
          id="main-body"
          data-name="main-body"
          fill={color}
          d="M15.23,135.5c.38,9.76.25,19.4.5,29.16.38,17,.76,34,1.27,50.86.38,14.71,1,29.42,1.27,44,.25,18.26,1.65,36.52,2.54,54.91.76,14.71,1.39,29.55,2.41,44.26,1,13.95,1.52,27.89,3.17,41.72,1.14,9.51,4.56,18.26,8.87,26.63,2.79,5.32,6.85,9.89,10,15,2.41,3.68,5.33,7.36,5.71,11.92.63,8.37.88,16.74,1.52,25.11.89,11,1.27,22.07,2,33.1.89,14.07,2,28.28,2.91,42.35.89,12.18,1.65,24.35,2.67,36.53,1.14,14.2,2.41,28.4,3.55,42.6.89,11.67,1.52,23.34,2.66,34.88.38,3.68.64,7.35.89,11.16a299.91,299.91,0,0,0,246.14,7.61c.76-6.72,1.4-13.45,2.16-20,1.52-12.68,3-25.49,4.56-38.17q2.28-19.41,4.57-38.8c1.65-13.32,3.42-26.64,4.56-40.08,1.4-15,2.41-29.93,3.93-44.76,1.4-14.59,2.67-29,3.05-43.63.12-4.18.25-8.37,2.53-12.3,5.2-8.62,11.29-16.61,16.87-24.85a48.14,48.14,0,0,0,7.74-21.69q2.85-28,4.94-55.92c1.27-17.25,2.28-34.49,3.68-51.61,1.27-16.87,2.15-33.61,3.17-50.47.89-13.95,1.65-27.9,2.53-41.85,1.15-17.76,1.91-35.51,2.41-53.39.38-13.7,1-27.26,1.4-41,.38-16.36,1.9-32.59,1.9-48.95V59A1401.89,1401.89,0,0,0,14,56.62c0,11.79,0,23.71.38,35.63C15.1,106.71,14.85,121,15.23,135.5Z"
        />
      </svg>
      <div
        style={{
          position: "absolute",
          top: "35px",
          left: "15px",
          width: "170px",
          height: "200px",
        }}
      >
      {topImage && (
        <Draggable
          nodeRef={topNodeRef}
          bounds="parent"
        >
          <div ref={topNodeRef} style={{ position: 'absolute', top: '50px', left: '25px', width: '150px', height: '150px', border: '1px solid blue' }}>
            <ResizableBox
              width={100}
              height={100}
              minConstraints={[50, 50]}
              maxConstraints={[150, 150]}
              lockAspectRatio
            >
              <img
                src={topImage}
                alt="Top"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </ResizableBox>
          </div>
        </Draggable>
      )}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "15px",
          left: "25px",
          width: "150px",
          height: "150px",
        }}
      ></div>
      {bottomImage && (
        <Draggable
          nodeRef={bottomNodeRef}
          bounds="parent"
        >
          <div ref={bottomNodeRef} style={{ position: 'absolute', bottom: '50px', left: '25px', width: '150px', height: '150px', border: '1px solid green' }}>
            <ResizableBox
              width={100}
              height={100}
              minConstraints={[50, 50]}
              maxConstraints={[150, 150]}
              lockAspectRatio
            >
              <img
                src={bottomImage}
                alt="Bottom"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </ResizableBox>
          </div>
        </Draggable>
      )}
    </div>
  );
};

BackView.propTypes = {
  color: PropTypes.string.isRequired,
  topImage: PropTypes.string,
  bottomImage: PropTypes.string,
};

export default BackView;
